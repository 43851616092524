import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { getDateFormatted } from "../utils/date"

export default function AllArticles({ pageContext: { articles } }) {

    return (
        <Layout>
            <Seo title='Latest news from the Open Membrane Database' />
            <div className="section">
                <div className="container is-max-desktop">
                    <h1 className="title is-1 mb-6">OMD News</h1>
                    {
                        articles.map(
                            article =>
                                <article className="mb-5" key={article.meta.slug}>
                                    <h1 className="title is-5">
                                        <Link
                                            className="has-text-dark"
                                            to={`/news/${article.meta.slug}`}
                                        >
                                            {article.meta.title}
                                        </Link>
                                    </h1>
                                    <p className="subtitle is-7 has-text-grey has-text-weight-bold mb-3">
                                        {getDateFormatted(article.dates.publication)}
                                    </p>
                                    <p>
                                        {article.meta.description}
                                        <br />
                                        <Link
                                            to={`/news/${article.meta.slug}`}
                                            className="is-size-6"
                                        >
                                            <u>Read more</u>
                                        </Link>
                                    </p>
                                </article>
                        )
                    }
                </div>
            </div>
        </Layout>
    )
}
